// extracted by mini-css-extract-plugin
export var animationContainer = "_66-0";
export var conversationContainer = "_66-1";
export var detailsContent = "_66-2";
export var message = "_66-3";
export var modalContent = "_66-4";
export var modalFooter = "_66-6";
export var modalHeader = "_66-5";
export var name = "_66-7";
export var optionContainer = "_66-8";
export var optionTitle = "_66-9";
export var pillContainer = "_66-a";
export var selectedConversationsContainer = "_66-b";
export var selectedOptionContainer = "_66-c";