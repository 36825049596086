// extracted by mini-css-extract-plugin
export var container = "_5e-0";
export var content = "_5e-3";
export var customerTitle = "_5e-4";
export var duration = "_5e-5";
export var empty = "_5e-6";
export var gray = "_5e-7";
export var header = "_5e-8";
export var info = "_5e-9";
export var loading = "_5e-2";
export var loadingCard = "_5e-a";
export var oneLine = "_5e-b";
export var personName = "_5e-f";
export var pillContainer = "_5e-c";
export var selected = "_5e-1";
export var text = "_5e-d";
export var twoLines = "_5e-e";
export var unread = "_5e-g";
export var unreadIndicator = "_5e-h";