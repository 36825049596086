// extracted by mini-css-extract-plugin
export var actionButton = "_76-1";
export var actions = "_76-0";
export var copyableField = "_76-2";
export var detailColumn = "_76-3";
export var detailRow = "_76-4";
export var dropdownTitle = "_76-5";
export var fulfillmentDetails = "_76-7";
export var fulfillments = "_76-6";
export var icon = "_76-8";
export var imgContainer = "_76-9";
export var lineItems = "_76-a";
export var link = "_76-p";
export var orderCard = "_76-k";
export var orderCollapseButton = "_76-l";
export var orderCollapseButtonIcon = "_76-m";
export var orderDetails = "_76-b";
export var orderPillsContainer = "_76-c";
export var pillWrapper = "_76-d";
export var product = "_76-e";
export var productDetails = "_76-g";
export var productTopDetails = "_76-h";
export var products = "_76-f";
export var strong = "_76-i";
export var tightText = "_76-o";
export var tooltipHidden = "_76-q";
export var variantQuantityRow = "_76-j";
export var wrappingText = "_76-n";