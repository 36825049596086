// extracted by mini-css-extract-plugin
export var aboveInput = "_5m-0";
export var actionButton = "_5m-4";
export var actionButtonContainer = "_5m-1";
export var actionIcon = "_5m-5";
export var aiInfoContainer = "_5m-2";
export var bold = "_5m-3";
export var checkIcon = "_5m-6";
export var clickingRecipientBar = "_5m-y";
export var commentThreadSwitch = "_5m-7";
export var draftImage = "_5m-8";
export var draftImageContainer = "_5m-9";
export var draftImages = "_5m-b";
export var editButton = "_5m-d";
export var editor = "_5m-c";
export var editorContainer = "_5m-v";
export var headerDivider = "_5m-e";
export var icon = "_5m-f";
export var info = "_5m-g";
export var internal = "_5m-k";
export var internalDivider = "_5m-h";
export var internalMessage = "_5m-i";
export var messageInputCard = "_5m-j";
export var messageInputForm = "_5m-l";
export var messageInputFormInternal = "_5m-m";
export var options = "_5m-n";
export var publicMessageCustomer = "_5m-p";
export var publicMessageMerchant = "_5m-o";
export var removeImageButton = "_5m-a";
export var replyButtons = "_5m-q";
export var showSignatureButton = "_5m-r";
export var spinner = "_5m-s";
export var visibilitySelector = "_5m-t";
export var warning = "_5m-u";
export var wrappingText = "_5m-w";
export var writingInternalNoteTopBorder = "_5m-x";