import { ClickAwayListener } from "@mui/material";
import {
  DateFilter,
  DateFilterQueryType,
  FilterGroupFilterOption,
  KnownDateFilterTimeFrame,
} from "@redotech/redo-model/conversation-filters";
import { RedoCheckbox } from "@redotech/redo-web/arbiter-components/checkbox/redo-checkbox";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
} from "@redotech/redo-web/arbiter-components/filter-group/redo-filter-group";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import { RedoSingleSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown.tsx/redo-single-select-dropdown";
import {
  RedoTimePicker,
  TimeOfDay,
} from "@redotech/redo-web/arbiter-components/time/redo-time-picker";
import {
  ConversationFilterOptionToIcon,
  ConversationFilterOptionToName,
} from "@redotech/redo-web/conversation-filters/conversation-filter-icons";
import { DateRangeValue } from "@redotech/redo-web/date-range";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { assertNever, Tuple } from "@redotech/util/type";
import { memo, useEffect, useMemo, useState } from "react";
import Calendar from "react-calendar";
import { Dropdown } from "../dropdown";

// CSS imports must be ordered
import "react-time-picker/dist/TimePicker.css";
//
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
//
import "react-calendar/dist/Calendar.css";
//
import "@redotech/redo-web/date-range.module.css";

export const ConversationTableDateFilterGroup = memo(
  function ConversationTableDateFilterGroup({
    dateFilter: { queryType, timeFrame, customDate },
    setDateFilter,
    removeFilter,
    filterOption,
  }: {
    filterOption: FilterGroupFilterOption;
    dateFilter: DateFilter;
    setDateFilter(dateFilter: DateFilter): void;
    removeFilter(): void;
  }) {
    const [queryRef, setQueryRef] = useState<HTMLButtonElement | null>(null);
    const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);

    function handleSetCustomDateValue(value: Date | Tuple<Date, 2>) {
      const sortedDates = Array.isArray(value) ? sortDates(value) : value;
      setDateFilter({
        queryType,
        timeFrame: KnownDateFilterTimeFrame.CUSTOM,
        customDate: sortedDates,
      });
    }

    function handleSetTimeFrame(
      newTimeFrameItem: RedoListItem<KnownDateFilterTimeFrame>,
    ) {
      const newTimeFrame = newTimeFrameItem.value;
      if (newTimeFrame === KnownDateFilterTimeFrame.CUSTOM) {
        setCustomDateDropdownOpen(true);
        return;
      }
      const oldTimeFrame = timeFrame;
      if (newTimeFrame === oldTimeFrame) {
        return;
      }
      setDateFilter({
        queryType,
        timeFrame: newTimeFrame,
        customDate: undefined,
      });
    }

    function handleSetDateQueryType(
      newQueryType: RedoListItem<DateFilterQueryType>,
    ) {
      const oldQueryType = queryType;
      if (newQueryType.value === oldQueryType) {
        return;
      }
      const newCustomDate =
        timeFrame === KnownDateFilterTimeFrame.CUSTOM
          ? wrangleCustomDateIntoProperForm(newQueryType.value, customDate)
          : undefined;
      setDateFilter({
        queryType: newQueryType.value,
        timeFrame,
        customDate: newCustomDate,
      });
    }

    const query = (
      <RedoFilterDropdownAnchor
        color="secondary"
        ref={setQueryRef}
        text={dateFilterQueryTypeToText[queryType]}
      />
    );

    const queryOptions: RedoListItem<DateFilterQueryType>[] = Object.values(
      DateFilterQueryType,
    ).map((item) => {
      return {
        value: item,
      };
    });

    const [customDateDropdownOpen, setCustomDateDropdownOpen] = useState(false);

    const queryDropdown = (
      <RedoSingleSelectDropdown
        dropdownButtonRef={queryRef}
        options={queryOptions}
        optionSelected={handleSetDateQueryType}
        selectedItem={{ value: queryType }}
        size={RedoListItemSize.SMALL}
      >
        {(item) => (
          <Text
            fontSize="sm"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {dateFilterQueryTypeToText[item.value]}
          </Text>
        )}
      </RedoSingleSelectDropdown>
    );

    const value = (
      <RedoFilterDropdownAnchor
        ref={setValueRef}
        text={getValueString(timeFrame, customDate)}
        weight="semibold"
      />
    );

    const valueOptions: RedoListItem<KnownDateFilterTimeFrame>[] =
      Object.values(KnownDateFilterTimeFrame).map((item) => {
        return {
          value: item,
        };
      });

    const valueDropdown = (
      <RedoSingleSelectDropdown
        dropdownButtonRef={valueRef}
        options={valueOptions}
        optionSelected={handleSetTimeFrame}
        selectedItem={timeFrame ? { value: timeFrame } : undefined}
        size={RedoListItemSize.SMALL}
      >
        {(item) => (
          <Text
            fontSize="sm"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {timeFrameToText[item.value]}
          </Text>
        )}
      </RedoSingleSelectDropdown>
    );

    return (
      <>
        {queryDropdown}
        {valueDropdown}
        <CustomDatePickerDropdown
          anchor={valueRef}
          customDate={customDate}
          open={customDateDropdownOpen}
          queryType={queryType}
          setCustomDate={handleSetCustomDateValue}
          setOpen={setCustomDateDropdownOpen}
        />
        <RedoFilterGroup
          Icon={ConversationFilterOptionToIcon[filterOption]}
          propertyName={ConversationFilterOptionToName[filterOption]}
          query={query}
          removeFilter={removeFilter}
          value={value}
        />
      </>
    );
  },
);

function CustomDatePickerDropdown({
  anchor,
  open,
  setOpen,
  customDate,
  setCustomDate,
  queryType,
}: {
  anchor: HTMLElement | null;
  open: boolean;
  setOpen(open: boolean): void;
  customDate: Date | Tuple<Date, 2> | undefined;
  setCustomDate(dates: Date | Tuple<Date, 2>): void;
  queryType: DateFilterQueryType;
}) {
  const [timesOfDayFromPicker, setTimesOfDayFromPicker] = useState<
    TimeOfDay | Tuple<TimeOfDay, 2> | undefined
  >();

  const [localCustomDates, setLocalCustomDates] = useState<
    Date | Tuple<Date, 2> | undefined
  >(customDate);

  useEffect(() => {
    setLocalCustomDates(customDate);
    setTimesOfDayFromPicker(undefined);
  }, [customDate]);

  const [customizeTimeOfDay, setCustomizeTimeOfDay] = useState(false);

  const timesOfDay: TimeOfDay | Tuple<TimeOfDay, 2> | undefined =
    useMemo(() => {
      if (!customizeTimeOfDay || !localCustomDates) {
        return undefined;
      }

      if (Array.isArray(localCustomDates)) {
        const timesOfDayFromDates = [
          localCustomDates[0],
          localCustomDates[1],
        ].map((date) => {
          return {
            hours: date.getHours(),
            minutes: date.getMinutes(),
          };
        }) as Tuple<TimeOfDay, 2>;

        if (timesOfDayFromPicker) {
          if (Array.isArray(timesOfDayFromPicker)) {
            return timesOfDayFromPicker;
          } else {
            return [timesOfDayFromPicker, timesOfDayFromDates[1]];
          }
        } else {
          return timesOfDayFromDates;
        }
      } else {
        if (timesOfDayFromPicker) {
          if (Array.isArray(timesOfDayFromPicker)) {
            return timesOfDayFromPicker[0];
          }
          return timesOfDayFromPicker;
        }
        return {
          hours: localCustomDates.getHours(),
          minutes: localCustomDates.getMinutes(),
        };
      }
    }, [localCustomDates, timesOfDayFromPicker, customizeTimeOfDay]);

  function handleClose(datesToCloseWith?: Date | Tuple<Date, 2>) {
    const dates = datesToCloseWith || localCustomDates;

    if (!dates) {
      setOpen(false);
      return;
    }

    if (!timesOfDay) {
      setCustomDate(dates);
      setOpen(false);
      return;
    }

    if (Array.isArray(timesOfDay) && Array.isArray(dates)) {
      const [startTime, endTime] = timesOfDay;
      const [startDate, endDate] = dates;
      const newCustomDate: [Date, Date] = [
        new Date(startDate),
        new Date(endDate),
      ];
      newCustomDate[0].setHours(startTime.hours, startTime.minutes);
      newCustomDate[1].setHours(endTime.hours, endTime.minutes);
      setCustomDate(newCustomDate);
    } else if (!Array.isArray(timesOfDay) && !Array.isArray(dates)) {
      const newCustomDate = new Date(dates);
      newCustomDate.setHours(timesOfDay.hours, timesOfDay.minutes);
      setCustomDate(newCustomDate);
    }

    setOpen(false);
  }

  function handleSetCustomizeTimeOfDay(customize: boolean) {
    if (!customize) {
      setTimesOfDayFromPicker(undefined);
      setLocalCustomDates(
        wrangleDatePickerSubmission(queryType, localCustomDates || null),
      );
    }
    setCustomizeTimeOfDay(customize);
  }

  function handleSetCustomDate(value: DateRangeValue | Date | Tuple<Date, 2>) {
    const formattedCustomDate = wrangleDatePickerSubmission(queryType, value);
    setLocalCustomDates(formattedCustomDate);
    if (!customizeTimeOfDay) {
      handleClose(formattedCustomDate);
    }
  }

  return (
    <>
      {open && (
        <ClickAwayListener onClickAway={() => handleClose()}>
          <Dropdown
            anchor={anchor}
            constrainHeight={false}
            fitToAnchor={false}
            open={open}
          >
            <Flex dir="column">
              <Calendar
                maxDate={new Date()}
                minDetail="year"
                onChange={handleSetCustomDate}
                selectRange={queryType === DateFilterQueryType.WITHIN}
                value={localCustomDates}
              />
              <RedoCheckbox
                label="Customize time of day"
                setValue={(value) => handleSetCustomizeTimeOfDay(!!value)}
                value={customizeTimeOfDay}
              />
              {customizeTimeOfDay &&
                Array.isArray(localCustomDates) &&
                Array.isArray(timesOfDay) && (
                  <RangeTimePicker
                    dates={localCustomDates}
                    setTimesOfDay={setTimesOfDayFromPicker}
                    timesOfDay={timesOfDay}
                  />
                )}
              {customizeTimeOfDay &&
                localCustomDates &&
                timesOfDay &&
                !Array.isArray(localCustomDates) &&
                !Array.isArray(timesOfDay) && (
                  <SingleDateTimePicker
                    date={localCustomDates}
                    setTimeOfDay={setTimesOfDayFromPicker}
                    timeOfDay={timesOfDay}
                  />
                )}
            </Flex>
          </Dropdown>
        </ClickAwayListener>
      )}
    </>
  );
}

function dateToTimeOfDay(date: Date): TimeOfDay {
  return {
    hours: date.getHours(),
    minutes: date.getMinutes(),
  };
}

function SingleDateTimePicker({
  date,
  timeOfDay,
  setTimeOfDay,
}: {
  date: Date;
  timeOfDay: TimeOfDay;
  setTimeOfDay(date: TimeOfDay): void;
}) {
  function handleSetStartTimeVal(newTimeOfDay: TimeOfDay | null) {
    const timeOfDayToSet = newTimeOfDay || dateToTimeOfDay(date);
    setTimeOfDay(timeOfDayToSet);
  }

  return (
    <Flex justify="space-between">
      <Text>{date.toDateString()}</Text>
      <Text> at </Text>
      <RedoTimePicker setValue={handleSetStartTimeVal} value={timeOfDay} />
    </Flex>
  );
}

function RangeTimePicker({
  dates,
  timesOfDay,
  setTimesOfDay,
}: {
  dates: Tuple<Date, 2>;
  timesOfDay: Tuple<TimeOfDay, 2>;
  setTimesOfDay(date: Tuple<TimeOfDay, 2>): void;
}) {
  function handleSetStartTimeVal(newTimeOfDay: TimeOfDay | null) {
    const timeOfDayToSet = newTimeOfDay || dateToTimeOfDay(dates[0]);
    setTimesOfDay([timeOfDayToSet, timesOfDay[1]]);
  }

  function handleSetEndTimeVal(newTimeOfDay: TimeOfDay | null) {
    const timeOfDayToSet = newTimeOfDay || dateToTimeOfDay(dates[1]);
    setTimesOfDay([timesOfDay[0], timeOfDayToSet]);
  }

  return (
    <Flex dir="column">
      <Flex justify="space-between">
        <Text>{dates[0].toDateString()}</Text>
        <Text> at </Text>
        <RedoTimePicker
          setValue={handleSetStartTimeVal}
          value={timesOfDay[0]}
        />
      </Flex>
      <Flex justify="space-between">
        <Text>{dates[1].toDateString()}</Text>
        <Text> at </Text>
        <RedoTimePicker setValue={handleSetEndTimeVal} value={timesOfDay[1]} />
      </Flex>
    </Flex>
  );
}

function formatDateTime(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return date.toLocaleString("en-US", options).replace(", ", " at ");
}

function getValueString(
  timeFrame: KnownDateFilterTimeFrame | null,
  dateRange: Date | Tuple<Date, 2> | undefined,
): string {
  if (!timeFrame) {
    return "...";
  }
  if (!dateRange || timeFrame !== KnownDateFilterTimeFrame.CUSTOM) {
    return timeFrameToText[timeFrame];
  }
  if (Array.isArray(dateRange)) {
    const [startDate, endDate] = dateRange;
    return `${formatDateTime(startDate)} - ${formatDateTime(endDate)}`;
  }
  return formatDateTime(dateRange);
}

const timeFrameToText: Record<KnownDateFilterTimeFrame, string> = {
  [KnownDateFilterTimeFrame.TODAY]: "Today",
  [KnownDateFilterTimeFrame.THIS_WEEK]: "This Week",
  [KnownDateFilterTimeFrame.LAST_WEEK]: "Last Week",
  [KnownDateFilterTimeFrame.THIS_MONTH]: "This Month",
  [KnownDateFilterTimeFrame.LAST_MONTH]: "Last Month",
  [KnownDateFilterTimeFrame.THIS_YEAR]: "This Year",
  [KnownDateFilterTimeFrame.LAST_YEAR]: "Last Year",
  [KnownDateFilterTimeFrame.CUSTOM]: "Custom",
};

const dateFilterQueryTypeToText: Record<DateFilterQueryType, string> = {
  [DateFilterQueryType.WITHIN]: "during",
  [DateFilterQueryType.BEFORE]: "before",
  [DateFilterQueryType.AFTER]: "after",
};

function sortDates(dates: Tuple<Date, 2>): Tuple<Date, 2> {
  return dates.sort((a, b) => a.getTime() - b.getTime()) as Tuple<Date, 2>;
}

function wrangleDatePickerSubmission(
  queryType: DateFilterQueryType,
  value: DateRangeValue | Date | Tuple<Date, 2>,
): Date | Tuple<Date, 2> {
  if (Array.isArray(value)) {
    const [startDate, endDate] = value;
    const definedStartDate = startDate || new Date();
    const definedEndDate = endDate || new Date();
    const newCustomDateRange = sortDates([definedStartDate, definedEndDate]);

    const newCustomDate = wrangleCustomDateIntoProperForm(
      queryType,
      newCustomDateRange,
    );

    return newCustomDate;
  } else if (value) {
    const newCustomDate = wrangleCustomDateIntoProperForm(queryType, value);
    return newCustomDate;
  } else {
    const newCustomDate = wrangleCustomDateIntoProperForm(
      queryType,
      new Date(),
    );
    return newCustomDate;
  }
}

function wrangleCustomDateIntoProperForm(
  queryType: DateFilterQueryType,
  customDate: Date | Tuple<Date, 2> | undefined,
): Date | Tuple<Date, 2> {
  if (queryType === DateFilterQueryType.WITHIN) {
    if (Array.isArray(customDate)) {
      return customDate;
    } else if (customDate) {
      const startOfDay = new Date(customDate);
      startOfDay.setHours(0, 0, 0, 0);
      const endofDay = new Date(customDate);
      endofDay.setHours(23, 59, 59, 999);
      return [startOfDay, endofDay];
    } else {
      const startOfDay = new Date();
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date();
      endOfDay.setHours(23, 59, 59, 999);
      return [startOfDay, endOfDay];
    }
  } else if (queryType === DateFilterQueryType.BEFORE) {
    if (Array.isArray(customDate)) {
      const beforeDate = new Date(customDate[0]);
      beforeDate.setHours(0, 0, 0, 0);
      return beforeDate;
    } else if (customDate) {
      const beforeDate = new Date(customDate);
      beforeDate.setHours(0, 0, 0, 0);
      return beforeDate;
    } else {
      const beforeDate = new Date();
      beforeDate.setHours(0, 0, 0, 0);
      return beforeDate;
    }
  } else if (queryType === DateFilterQueryType.AFTER) {
    if (Array.isArray(customDate)) {
      const afterDate = new Date(customDate[1]);
      afterDate.setHours(23, 59, 59, 999);
      return afterDate;
    } else if (customDate) {
      const afterDate = new Date(customDate);
      afterDate.setHours(23, 59, 59, 999);
      return afterDate;
    } else {
      const afterDate = new Date();
      afterDate.setHours(23, 59, 59, 999);
      return afterDate;
    }
  } else {
    assertNever(queryType);
  }
}
