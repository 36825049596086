// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "_7k-0";
export var footer = "_7k-2";
export var infoSection = "_7k-3";
export var infoSectionContainer = "_7k-4";
export var infoSectionTitleRow = "_7k-5";
export var itemDetails = "_7k-6";
export var line = "_7k-7";
export var lineItemImage = "_7k-1";
export var lineItems = "_7k-8";
export var modalContent = "_7k-9";
export var multiLineInfo = "_7k-a";
export var orderName = "_7k-b";
export var strong = "_7k-c";