// extracted by mini-css-extract-plugin
export var centered = "_2i-6";
export var flexible = "_2i-5";
export var footer = "_2i-k";
export var footerBorder = "_2i-l";
export var header = "_2i-c";
export var headerBorder = "_2i-d";
export var headerDefault = "_2i-g";
export var headerMedium = "_2i-h";
export var headerPadding = "_2i-e";
export var iconAndTitle = "_2i-f";
export var large = "_2i-4";
export var largePadding = "_2i-a";
export var medium = "_2i-3";
export var mediumPadding = "_2i-9";
export var modal = "_2i-0";
export var modalContainer = "_2i-b";
export var noPadding = "_2i-7";
export var skinny = "_2i-1";
export var small = "_2i-2";
export var smallPadding = "_2i-8";
export var subtitle = "_2i-j";
export var title = "_2i-i";