// extracted by mini-css-extract-plugin
export var actionButton = "_6o-0";
export var assigneeDetails = "_6o-1";
export var assigneeInfo = "_6o-2";
export var assigneeProfile = "_6o-4";
export var buttonContent = "_6o-5";
export var ccEmails = "_6o-a";
export var ccEmailsSection = "_6o-9";
export var chatbotContainer = "_6o-6";
export var collapseContainer = "_6o-c";
export var copyableField = "_6o-d";
export var customerInfoTitle = "_6o-f";
export var detailRow = "_6o-h";
export var details = "_6o-e";
export var detailsText = "_6o-l";
export var disclaimer = "_6o-b";
export var editAssigneeForm = "_6o-j";
export var editAssigneeInfo = "_6o-3";
export var editAssigneeModalContainer = "_6o-i";
export var editButton = "_6o-g";
export var email = "_6o-7";
export var header = "_6o-m";
export var headerDivider = "_6o-n";
export var icon = "_6o-o";
export var instaDesc = "_6o-x";
export var ordersReturnsSection = "_6o-v";
export var postPreview = "_6o-w";
export var profilePicture = "_6o-p";
export var rightContent = "_6o-8";
export var saveButton = "_6o-k";
export var strong = "_6o-q";
export var tagsAndAssigneeContainer = "_6o-t";
export var username = "_6o-r";
export var withBottomBorder = "_6o-u";
export var wrapper = "_6o-s";
export var wrappingText = "_6o-y";