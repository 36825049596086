import { z } from "zod";
import { buttonHierarchies } from "../../../brand-kit"; // REVIEWS-TODO TODO-bcarper move to common location
import { zExt } from "../../../common/zod-util";
import { BaseElementType } from "../../../content-builder";
import { buttonActions, inputTypes } from "../../../signup-form"; // REVIEWS-TODO TODO-bcarper move to common location

const formElementDbWriteValidatorBase = z.object({
  type: z.nativeEnum(BaseElementType),
});

const formShortAnswerElementDbWriteValidator =
  formElementDbWriteValidatorBase.extend({
    type: z.literal(BaseElementType.SHORT_ANSWER),
    title: z.string(),
    placeholderText: z.string(),
    required: z.boolean(),
  });
const formShortAnswerElementDbParser =
  formShortAnswerElementDbWriteValidator.extend({
    _id: zExt.objectId(),
  });

const formRichTextElementDbWriteValidator =
  formElementDbWriteValidatorBase.extend({
    type: z.literal(BaseElementType.RICH_TEXT),
    id: z.number(),
    html: z.string(),
    fontFamily: z.string(),
    fontWeight: z.string(),
    fontSizePx: z.number(),
    textColor: z.string(),
    linkColor: z.string(),
  });
const formRichTextElementDbParser = formRichTextElementDbWriteValidator.extend({
  _id: zExt.objectId(),
});

const formButtonElementDbWriteValidator =
  formElementDbWriteValidatorBase.extend({
    type: z.literal(BaseElementType.BUTTON),
    hierarchy: z.enum(buttonHierarchies),
    text: z.string(),
    action: z.enum(buttonActions),
  });
const formButtonElementDbParser = formButtonElementDbWriteValidator.extend({
  _id: zExt.objectId(),
});

const formInputElementDbWriteValidator = formElementDbWriteValidatorBase.extend(
  {
    type: z.literal(BaseElementType.INPUT),
    inputType: z.enum(inputTypes),
    placeholderText: z.string(),
  },
);
const formInputElementDbParser = formInputElementDbWriteValidator.extend({
  _id: zExt.objectId(),
});

const formLogoElementDbWriteValidator = formElementDbWriteValidatorBase.extend({
  type: z.literal(BaseElementType.LOGO),
  widthPercentage: z.number(),
});
const formLogoElementDbParser = formLogoElementDbWriteValidator.extend({
  _id: zExt.objectId(),
});

const formImageElementDbWriteValidator = formElementDbWriteValidatorBase.extend(
  {
    type: z.literal(BaseElementType.IMAGE),
    url: z.string(),
  },
);
const formImageElementDbParser = formImageElementDbWriteValidator.extend({
  _id: zExt.objectId(),
});

const formProductDetailsElementDbWriteValidator =
  formElementDbWriteValidatorBase.extend({
    type: z.literal(BaseElementType.PRODUCT_DETAILS),
    imageWidthPercentage: z.number(),
  });
const formProductDetailsElementDbParser =
  formProductDetailsElementDbWriteValidator.extend({
    _id: zExt.objectId(),
  });

const formStarsElementDbWriteValidator = formElementDbWriteValidatorBase.extend(
  {
    type: z.literal(BaseElementType.STARS),
    title: z.string(),
    required: z.boolean(),
  },
);
const formStarsElementDbParser = formStarsElementDbWriteValidator.extend({
  _id: zExt.objectId(),
});

const formMediaUploadElementDbWriteValidator =
  formElementDbWriteValidatorBase.extend({
    type: z.literal(BaseElementType.MEDIA_UPLOAD),
    widthPercentage: z.number(),
    required: z.boolean(),
  });

const formMediaUploadElementDbParser =
  formMediaUploadElementDbWriteValidator.extend({
    _id: zExt.objectId(),
  });

const formSingleSelectElementDbWriteValidator =
  formElementDbWriteValidatorBase.extend({
    type: z.literal(BaseElementType.SINGLE_SELECT),
    options: z.array(z.string()),
    required: z.boolean(),
  });

const formSingleSelectElementDbParser =
  formSingleSelectElementDbWriteValidator.extend({
    _id: zExt.objectId(),
  });

const formParagraphElementDbWriteValidator =
  formElementDbWriteValidatorBase.extend({
    type: z.literal(BaseElementType.PARAGRAPH),
    title: z.string(),
    placeholderText: z.string(),
    required: z.boolean(),
  });

const formParagraphElementDbParser =
  formParagraphElementDbWriteValidator.extend({
    _id: zExt.objectId(),
  });

export const formElementDbWriteValidator = z.discriminatedUnion("type", [
  formShortAnswerElementDbWriteValidator,
  formRichTextElementDbWriteValidator,
  formButtonElementDbWriteValidator,
  formInputElementDbWriteValidator,
  formLogoElementDbWriteValidator,
  formImageElementDbWriteValidator,
  formProductDetailsElementDbWriteValidator,
  formStarsElementDbWriteValidator,
  formMediaUploadElementDbWriteValidator,
  formSingleSelectElementDbWriteValidator,
  formParagraphElementDbWriteValidator,
]);

export const formElementDbParser = z.discriminatedUnion("type", [
  formShortAnswerElementDbParser,
  formRichTextElementDbParser,
  formButtonElementDbParser,
  formInputElementDbParser,
  formLogoElementDbParser,
  formImageElementDbParser,
  formProductDetailsElementDbParser,
  formStarsElementDbParser,
  formMediaUploadElementDbParser,
  formSingleSelectElementDbParser,
  formParagraphElementDbParser,
]);
