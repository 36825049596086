// extracted by mini-css-extract-plugin
export var chip = "_28-a";
export var chipLabel = "_28-b";
export var close = "_28-8";
export var closeIcon = "_28-9";
export var container = "_28-3";
export var error = "_28-7";
export var infoTooltip = "_28-2";
export var innerContainer = "_28-1";
export var input = "_28-c";
export var medium = "_28-6";
export var outerContainer = "_28-0";
export var small = "_28-5";
export var xSmall = "_28-4";