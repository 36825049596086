import { z } from "zod";
import { brandKitValidator } from "../../../brand-kit";
import { generateFormBuilderFormDbParser } from "../common/form-builder-form-db-validator";
import {
  formElementDbParser,
  formElementDbWriteValidator,
} from "../common/form-builder-form-element-db-validator";

const reviewFormDataSettingsDbParser = z.object({
  title: z.string(),
  description: z.string(),
  categories: z.array(z.string()),
});
const reviewFormDataSettingsDbWriteValidator = reviewFormDataSettingsDbParser;

const reviewFormDataStylesDbParser = z.object({
  brandKitEnabled: z.boolean(),
  brandKitOverride: brandKitValidator,
});
const reviewFormDataStylesDbWriteValidator = reviewFormDataStylesDbParser;

const reviewFormDataPageDbWriteValidator = z.object({
  elements: z.array(formElementDbWriteValidator),
});
const reviewFormDataPageDbParser = reviewFormDataPageDbWriteValidator.extend({
  elements: z.array(formElementDbParser),
});

export const reviewFormDataDbWriteValidator = z.object({
  settings: reviewFormDataSettingsDbWriteValidator,
  styles: reviewFormDataStylesDbWriteValidator,
  pages: z.array(reviewFormDataPageDbWriteValidator),
  thankYouPage: z.object({}),
});
export const reviewFormDataDbParser = reviewFormDataDbWriteValidator.extend({
  settings: reviewFormDataSettingsDbParser,
  styles: reviewFormDataStylesDbParser,
  pages: z.array(reviewFormDataPageDbParser),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const reviewFormBuilderFormDbParser = generateFormBuilderFormDbParser(
  reviewFormDataDbParser,
);
export type ReviewFormBuilderForm = z.infer<
  typeof reviewFormBuilderFormDbParser
>;
