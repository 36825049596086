// extracted by mini-css-extract-plugin
export var danger = "_30-6";
export var lightPink = "_30-9";
export var medium = "_30-i";
export var nearSquare = "_30-l";
export var neutralBlue = "_30-8";
export var neutralYellow = "_30-7";
export var noCapitalize = "_30-1";
export var normal = "_30-2";
export var pill = "_30-0";
export var primary = "_30-3";
export var primaryLight = "_30-4";
export var small = "_30-j";
export var solidBlack = "_30-h";
export var solidGreen = "_30-c";
export var solidLightBlue = "_30-e";
export var solidNavy = "_30-d";
export var solidOrange = "_30-g";
export var solidPink = "_30-f";
export var solidRed = "_30-a";
export var solidYellow = "_30-b";
export var success = "_30-5";
export var xsmall = "_30-k";