// extracted by mini-css-extract-plugin
export var actionButton = "_50-t";
export var buttonContainer = "_50-0";
export var buttonContainer2 = "_50-1";
export var buttonSection = "_50-2";
export var details = "_50-3";
export var dropdownItem = "_50-5";
export var dropdownItemsContainer = "_50-4";
export var fileInput = "_50-r";
export var headerButton = "_50-6";
export var icon = "_50-7";
export var input = "_50-8";
export var macroListItem = "_50-9";
export var mainContent = "_50-a";
export var microText = "_50-b";
export var modal = "_50-w";
export var optionsButton = "_50-c";
export var paperclip = "_50-s";
export var pillContent = "_50-d";
export var preview = "_50-e";
export var preview2 = "_50-f";
export var quillEditor = "_50-q";
export var selectedMacro = "_50-g";
export var sidebar = "_50-h";
export var sidebarHeader = "_50-j";
export var sidebarList = "_50-i";
export var sidebarListItem = "_50-k";
export var sidebarSearch = "_50-l";
export var snoozeDurationWrapper = "_50-v";
export var statusPillContainer = "_50-m";
export var tagsContainer = "_50-n";
export var unselectedMacro = "_50-o";
export var variable = "_50-u";
export var wrapper = "_50-p";