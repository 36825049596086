// extracted by mini-css-extract-plugin
export var emailContainer = "_7d-3";
export var emailContentPaddedContainer = "_7d-9";
export var emailHeader = "_7d-5";
export var emailInfoIcon = "_7d-8";
export var emailShortenedSummary = "_7d-c";
export var infoDropdown = "_7d-0";
export var infoRowBoundedContent = "_7d-b";
export var internal = "_7d-4";
export var merchant = "_7d-7";
export var normal = "_7d-6";
export var replyCard = "_7d-d";
export var subtleButton = "_7d-1";
export var subtleButtonIconContainer = "_7d-2";
export var toggleViewRepliesButton = "_7d-a";
export var wrappingText = "_7d-e";