// extracted by mini-css-extract-plugin
export var acitonButtons = "_5n-0";
export var block = "_5n-1";
export var blockContent = "_5n-7";
export var blockDescription = "_5n-8";
export var blockIcon = "_5n-9";
export var blockTitle = "_5n-6";
export var border = "_5n-a";
export var center = "_5n-k";
export var edge = "_5n-b";
export var edgeLabel = "_5n-c";
export var edgePath = "_5n-d";
export var error = "_5n-e";
export var flowchart = "_5n-f";
export var flowchartContent = "_5n-g";
export var highlighted = "_5n-3";
export var hover = "_5n-4";
export var icon = "_5n-h";
export var left = "_5n-l";
export var navButton = "_5n-i";
export var navButtons = "_5n-j";
export var right = "_5n-m";
export var selected = "_5n-2";
export var small = "_5n-5";