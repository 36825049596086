// extracted by mini-css-extract-plugin
export var copyableField = "_7c-4";
export var customerBadge = "_7c-0";
export var customerInfoTitle = "_7c-2";
export var details = "_7c-1";
export var dropdownContainer = "_7c-5";
export var dropdownImage = "_7c-6";
export var editAssigneeForm = "_7c-a";
export var editButton = "_7c-3";
export var email = "_7c-7";
export var hidden = "_7c-d";
export var input = "_7c-8";
export var saveButton = "_7c-b";
export var shopifyIcon = "_7c-c";
export var username = "_7c-9";