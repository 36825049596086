// extracted by mini-css-extract-plugin
export var alignCenter = "_4s-b";
export var buttonContent = "_4s-0";
export var cardList = "_4s-1";
export var centerContent = "_4s-2";
export var chevron = "_4s-3";
export var closePanelButton = "_4s-h";
export var conversationDetailContainer = "_4s-4";
export var detailWrapper = "_4s-5";
export var dropdownButton = "_4s-c";
export var dropdownTitle = "_4s-7";
export var hidden = "_4s-8";
export var icon = "_4s-9";
export var panel = "_4s-g";
export var portalButtonsWrapper = "_4s-a";
export var tableSummaryContainer = "_4s-6";
export var tableSummaryHeader = "_4s-e";
export var threeDotsButton = "_4s-d";
export var wide = "_4s-f";