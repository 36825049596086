// extracted by mini-css-extract-plugin
export var bubble = "_2n-0";
export var container = "_2n-3";
export var contrastBorder = "_2n-4";
export var greyOut = "_2n-2";
export var initialsWrapper = "_2n-1";
export var large = "_2n-9";
export var medium = "_2n-8";
export var mediumSmall = "_2n-7";
export var micro = "_2n-5";
export var small = "_2n-6";
export var tiny = "_2n-b";
export var xTiny = "_2n-a";