// extracted by mini-css-extract-plugin
export var buttons = "_44-0";
export var buttonsActive = "_44-h";
export var cancelConfirm = "_44-1";
export var closeButton = "_44-2";
export var column = "_44-3";
export var container = "_44-4";
export var detailHeader = "_44-6";
export var detailTitle = "_44-7";
export var detailsContent = "_44-5";
export var header = "_44-8";
export var headerWithClose = "_44-9";
export var icon = "_44-a";
export var infoIcon = "_44-c";
export var informationContainer = "_44-b";
export var sideDrawer = "_44-d";
export var sideDrawerOpen = "_44-e";
export var stickyFooter = "_44-f";
export var typeOption = "_44-g";