// extracted by mini-css-extract-plugin
export var border = "_42-5";
export var button = "_42-f";
export var center = "_42-7";
export var centered = "_42-3";
export var closeButton = "_42-9";
export var disabledWrapper = "_42-g";
export var error = "_42-j";
export var fill = "_42-c";
export var footer = "_42-b";
export var footerAction = "_42-d";
export var footerButtons = "_42-e";
export var header = "_42-4";
export var icon = "_42-i";
export var info = "_42-m";
export var inline = "_42-6";
export var large = "_42-2";
export var main = "_42-a";
export var modal = "_42-0";
export var none = "_42-n";
export var small = "_42-1";
export var success = "_42-l";
export var tight = "_42-h";
export var titleIcon = "_42-8";
export var warn = "_42-k";