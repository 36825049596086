// extracted by mini-css-extract-plugin
export var background = "_v-6";
export var containerMedium = "_v-0";
export var containerMicro = "_v-2";
export var containerSmall = "_v-1";
export var dropdown = "_v-5";
export var dropdownLabel = "_v-a";
export var enter = "_v-7";
export var exitActive = "_v-8";
export var label = "_v-3";
export var option = "_v-9";
export var select = "_v-4";