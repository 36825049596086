// extracted by mini-css-extract-plugin
export var active = "_2l-3";
export var activeWrapper = "_2l-0";
export var button = "_2l-1";
export var collapsed = "_2l-i";
export var divider = "_2l-7";
export var dividerSection = "_2l-6";
export var dropdown = "_2l-8";
export var dropdownChevron = "_2l-a";
export var expand = "_2l-9";
export var fullscreen = "_2l-r";
export var icon = "_2l-n";
export var label = "_2l-5 _k-0";
export var labelWithBubble = "_2l-p _k-0";
export var logo = "_2l-j";
export var logoSection = "_2l-m";
export var main = "_2l-q";
export var menu = "_2l-b";
export var menuHeader = "_2l-c _k-0";
export var menuItem = "_2l-d _k-0";
export var nav = "_2l-e";
export var noBackground = "_2l-2";
export var option = "_2l-4";
export var options = "_2l-g";
export var optionsContainer = "_2l-f";
export var page = "_2l-h";
export var rotate = "_2l-o";
export var section = "_2l-k";
export var settings = "_2l-t";
export var subitem = "_2l-s";
export var subitemActive = "_2l-x";
export var subitemLabel = "_2l-w";
export var subitemLine = "_2l-v";
export var subitemLineContainer = "_2l-u";
export var subsection = "_2l-l";