// extracted by mini-css-extract-plugin
export var colorSquare = "_7m-0";
export var danger = "_7m-5";
export var lightPink = "_7m-8";
export var neutralBlue = "_7m-7";
export var neutralYellow = "_7m-6";
export var normal = "_7m-1";
export var primary = "_7m-2";
export var primaryLight = "_7m-3";
export var solidBlack = "_7m-g";
export var solidGreen = "_7m-b";
export var solidLightBlue = "_7m-d";
export var solidNavy = "_7m-c";
export var solidOrange = "_7m-f";
export var solidPink = "_7m-e";
export var solidRed = "_7m-9";
export var solidYellow = "_7m-a";
export var success = "_7m-4";