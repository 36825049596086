import { ObjectId } from "bson";
import {
  BorderStrokeStyleDefinition,
  BorderStyleDefinition,
  ButtonOptionsOverride,
  ButtonStyleDefinition,
  Enableable,
  FontFamily,
  FontWeight,
  HexCode,
  InputStyleDefinition,
  Override,
  defaultBrandKit,
  defaultTertiaryButton,
} from "./brand-kit";
import {
  BaseElementType,
  ButtonElement,
  ImageElement,
  InputElement,
  RichTextElement,
} from "./content-builder";

export const directions = [
  "top",
  "right",
  "bottom",
  "left",
  "no",
  "background",
] as const;
export type Direction = (typeof directions)[number];

export const layoutTypes = ["popup", "fullscreen"] as const;
export type LayoutType = (typeof layoutTypes)[number];

export const inputTypes = ["email", "phone"] as const;
export type InputType = (typeof inputTypes)[number];

export const captureGoalTypes = ["phone", "email", "both"] as const;
export type CaptureGoalType = (typeof captureGoalTypes)[number];

export const deviceOptions = ["desktop", "mobile", "desktop & mobile"] as const;
export type DeviceOption = (typeof deviceOptions)[number];

export const discountTypes = ["percent", "fixed"] as const;
export type DiscountType = (typeof discountTypes)[number];

export const targetingTypes = [
  "all visitors",
  "exclude existing contacts",
  "only existing contacts",
] as const;
export type TargetingType = (typeof targetingTypes)[number];

export const buttonActions = ["submit-and-advance", "close"] as const;
export type ButtonAction = (typeof buttonActions)[number];

export const teaserVisibility = ["before-displaying", "after-closing"] as const;
export type TeaserVisibility = (typeof teaserVisibility)[number];

export const positionHorizontalValues = ["left", "center", "right"] as const;
export type PositionHorizontal = (typeof positionHorizontalValues)[number];

export const positionVerticalValues = ["top", "center", "bottom"] as const;
export type PositionVertical = (typeof positionVerticalValues)[number];

export const fontWeightValues: Record<FontWeight, number> = {
  regular: 400,
  semibold: 600,
  bold: 700,
};

export interface SignupForm {
  name: string;
  theme: {
    overrides: {
      buttons: Enableable<ButtonOptionsOverride>;
      inputs: Enableable<Override<InputStyleDefinition>>;
    };
    border: BorderStyleDefinition;
    closeButton: {
      color: HexCode;
      background: Enableable<{ color: HexCode }>;
    };
    overlay: {
      // could be combined as a single hexcode,
      // but native HTML color input (used by our ColorPicker) doesn't support alpha
      color: HexCode;
      opacityPercent: number; // 0-100
    };
  };
  layout: {
    type: LayoutType;
    backgroundColor: HexCode;
    image: {
      style: Direction;
      url: string;
    };
  };
  discount: Enableable<{
    type: DiscountType;
    // keep both fields for flexibility
    // and preserving data when switching between types
    amountUsd: number;
    percentage: number; // 0-100
  }>;
  behavior: {
    subscriberTags: string[];
    display: {
      timeOnPage: Enableable<{ seconds: number }>;
      exitIntent: Enableable<{}>;
    };
    targeting: TargetingType;
    appearsOn: DeviceOption;
  };
  teaser: Enableable<Teaser>;
  steps: FormStep[];
}

export interface SignupFormWithAnalytics {
  _id: string | ObjectId;
  team: string | ObjectId;
  form: SignupForm;
  publishedAt: Date | null;
  analytics: {
    views: number; // unique views (one per visitor)
    submissions: number;
  };
}

export const FormElementTypeValues = [
  BaseElementType.RICH_TEXT,
  BaseElementType.BUTTON,
  BaseElementType.INPUT,
  BaseElementType.IMAGE,
] as const;
export type FormElementType = Extract<
  BaseElementType,
  (typeof FormElementTypeValues)[number]
>;

export interface FormStep {
  elements: FormElement[];
}

export interface Teaser {
  text: string;
  fontFamily: FontFamily;
  fontWeight: FontWeight;
  fontSizePx: number;
  positionHorizontal: PositionHorizontal;
  positionVertical: PositionVertical;
  backgroundColor: HexCode;
  textColor: HexCode;
  cornerRadiusPx: number;
  borderStroke: BorderStrokeStyleDefinition;
  iconVisible: boolean;
  visibility: TeaserVisibility;
}

export type FormElement =
  | RichTextElement
  | ButtonElement
  | InputElement
  | ImageElement;

export const exampleSteps: FormStep[] = [
  {
    elements: [
      {
        id: 1,
        type: BaseElementType.RICH_TEXT,
        html: "<b>Get 20% off your first order</b>",
        fontFamily: "Arial",
        fontWeight: "bold",
        fontSizePx: 32,
        textColor: "#000000",
        linkColor: "#0000ff",
      },
      {
        id: 2,
        type: BaseElementType.RICH_TEXT,
        html: "<p>Sign up for special offers and updates</p>",
        fontFamily: "Arial",
        fontWeight: "regular",
        fontSizePx: 24,
        textColor: "#000000",
        linkColor: "#0000ff",
      },
      {
        type: BaseElementType.INPUT,
        inputType: "phone",
        placeholderText: "Enter your phone number",
      },
      {
        type: BaseElementType.BUTTON,
        hierarchy: "primary",
        text: "Sign up",
        action: "submit-and-advance",
      },
    ],
  },
  {
    elements: [
      {
        id: 1,
        type: BaseElementType.RICH_TEXT,
        html: "<p>Success!</p>",
        fontFamily: "Arial",
        fontWeight: "bold",
        fontSizePx: 32,
        textColor: "#000000",
        linkColor: "#0000ff",
      },
      {
        type: BaseElementType.BUTTON,
        hierarchy: "primary",
        text: "Close",
        action: "close",
      },
    ],
  },
];

export const defaultTertiaryOverride: Override<ButtonStyleDefinition> = {
  ...defaultTertiaryButton,
  font: {
    fontSizePx: defaultBrandKit?.font.hierarchy.body.fontSizePx,
    fontWeight: defaultBrandKit?.font.hierarchy.body.fontWeight,
    fontFamily: defaultBrandKit?.font.fontFamily,
  },
};

export const exampleForm: SignupForm = {
  name: "Signup Discount Form",
  theme: {
    overrides: {
      buttons: {
        enabled: false,
        primary: {
          ...defaultBrandKit?.buttons.primary,
          font: {
            fontSizePx: defaultBrandKit?.font.hierarchy.body.fontSizePx,
            fontWeight: defaultBrandKit?.font.hierarchy.body.fontWeight,
            fontFamily: defaultBrandKit?.font.fontFamily,
          },
        },
        secondary: {
          ...defaultBrandKit?.buttons.secondary,
          font: {
            fontSizePx: defaultBrandKit?.font.hierarchy.body.fontSizePx,
            fontWeight: defaultBrandKit?.font.hierarchy.body.fontWeight,
            fontFamily: defaultBrandKit?.font.fontFamily,
          },
        },
        tertiary: {
          ...defaultTertiaryButton,
          font: {
            fontSizePx: defaultBrandKit?.font.hierarchy.body.fontSizePx,
            fontWeight: defaultBrandKit?.font.hierarchy.body.fontWeight,
            fontFamily: defaultBrandKit?.font.fontFamily,
          },
        },
      },
      inputs: {
        ...defaultBrandKit?.inputs,
        enabled: false,
        font: {
          fontSizePx: defaultBrandKit?.font.hierarchy.body.fontSizePx,
          fontWeight: defaultBrandKit?.font.hierarchy.body.fontWeight,
          fontFamily: defaultBrandKit?.font.fontFamily,
        },
      },
    },
    closeButton: {
      color: "#000000",
      background: { enabled: false, color: "#ffffff" },
    },
    border: {
      cornerRadiusPx: 0,
      stroke: {
        color: "#000000",
        weightPx: 1,
      },
    },
    overlay: {
      color: "#000000",
      opacityPercent: 75,
    },
  },
  layout: {
    type: "popup",
    backgroundColor: "#ffffff",
    image: {
      style: "left",
      url: "",
    },
  },
  discount: {
    enabled: true,
    type: "percent",
    percentage: 20,
    amountUsd: 5,
  },
  behavior: {
    appearsOn: "desktop & mobile",
    subscriberTags: ["form_subscriber"],
    display: {
      timeOnPage: { enabled: true, seconds: 10 },
      exitIntent: { enabled: false }, // TODO: support this
    },
    targeting: "all visitors",
  },
  teaser: {
    enabled: true,
    text: "Get 20% off",
    fontFamily: "Arial",
    fontWeight: "bold",
    fontSizePx: 16,
    positionHorizontal: "left",
    positionVertical: "bottom",
    backgroundColor: "#000000",
    textColor: "#ffffff",
    cornerRadiusPx: 5,
    borderStroke: {
      color: "#000000",
      weightPx: 1,
    },
    iconVisible: true,
    visibility: "before-displaying",
  },
  steps: exampleSteps,
};
