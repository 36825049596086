// extracted by mini-css-extract-plugin
export var brand = "_3e-4";
export var compact = "_3e-7";
export var extraSmall = "_3e-8";
export var icon = "_3e-0";
export var large = "_3e-1";
export var medium = "_3e-2";
export var outline = "_3e-9";
export var primaryDark = "_3e-5";
export var primaryLight = "_3e-3";
export var primaryModern = "_3e-6";