// extracted by mini-css-extract-plugin
export var actionButton = "_74-1";
export var actions = "_74-0";
export var copyableField = "_74-2";
export var icon = "_74-3";
export var overflowVisible = "_74-4";
export var pillWrapper = "_74-5";
export var productDetails = "_74-7";
export var productPillsContainer = "_74-8";
export var productTopDetails = "_74-9";
export var products = "_74-6";
export var returnDetails = "_74-a";
export var strong = "_74-b";
export var variantQuantityRow = "_74-c";