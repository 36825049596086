// extracted by mini-css-extract-plugin
export var animationContainer = "_7r-0";
export var footer = "_7r-1";
export var gray = "_7r-2";
export var lineItem = "_7r-3";
export var lineItemImage = "_7r-4";
export var lineItemInfo = "_7r-5";
export var lineItemText = "_7r-6";
export var lineItems = "_7r-7";
export var modalContent = "_7r-8";
export var options = "_7r-9";
export var orderName = "_7r-a";
export var refundAmount = "_7r-b";