import { ClientOptions, createRpcClient } from "@redotech/rpc/client";
import { InferRpcDefinition } from "@redotech/rpc/definition";
import { getWarrantyEligibleProductsSchema } from "@redotech/server/utils/warranties/get-warranty-eligible-products-schema";
import { submitWarrantyRegistrationSchema } from "@redotech/server/utils/warranties/submit-warranty-registration-schema";
import { getAssistantSettingsSchema } from "./assistant/get-assistant-settings-schema";
import { getConciergeConversationsSchema } from "./assistant/get-concierge-conversations-schema";
import { getConciergeConversationMetricsSchema } from "./assistant/get-conversation-metrics-schema";
import { updateAssistantSettingsSchema } from "./assistant/update-assistant-settings-schema";
import { calculateNewOrderValueSchema } from "./calculate-new-order-value-schema";
import {
  createCustomReport,
  deleteCustomReport,
  getCustomReport,
  getCustomReports,
  updateCustomReport,
} from "./custom-reports";
import { disconnectRivoIntegrationSchema } from "./customer-accounts/rivo/disconnect-rivo-integration-schema";
import { getRivoIntegrationSchema } from "./customer-accounts/rivo/get-rivo-integration-schema";
import { setRivoIntegrationSchema } from "./customer-accounts/rivo/set-rivo-integration-schema";
import { getCustomerAccountsMetricsForOverviewSchema } from "./get-customer-accounts-metrics-for-overview-schema";
import { getEmailTemplateNamesSchema } from "./get-email-template-names-schema";
import { getOrdersSchema } from "./get-orders-schema";
import { getReturnsInstantExchangeRecoveryHistorySchema } from "./get-returns-instant-exchange-recovery-history-schema";
import { getReviewsAverageByProjectSchema } from "./get-reviews-average-by-project";
import { getReviewsByProductIdSchema } from "./get-reviews-by-product-id-schema";
import { getReviewsByTeamIdSchema } from "./get-reviews-by-team-id-schema";
import { getReviewsSchema } from "./get-reviews-schema";
import { getSaveForLaterStorefrontDisplaySettingsSchema } from "./get-save-for-later-storefront-display-settings-schema";
import { issueStoreCreditToCustomerSchema } from "./issue-store-credit-to-customer-schema";
import { recreateDiscountsSchema } from "./recreate-discounts-schema";
import { removeVariantExchangeItemSchema } from "./remove-variant-exchange-item-schema";
import { resendEmailSchema } from "./resend-email-schema";
import { createReviewFormSchema } from "./review-form/create-review-form-schema";
import { deleteReviewFormSchema } from "./review-form/delete-review-form-schema";
import { getActiveReviewFormForTeamSchema } from "./review-form/get-active-review-form-for-team-schema";
import { getReviewFormSchema } from "./review-form/get-review-form-schema";
import { getReviewFormsForTeamSchema } from "./review-form/get-review-forms-for-team-schema";
import { updateReviewFormSchema } from "./review-form/update-review-form-schema";
import { getRedoSalesChannelsSchema } from "./sales-channels/get-redo-sales-channels-schema";
import { getSalesChannelOptionsSchema } from "./sales-channels/get-sales-channel-options-schema";
import { setRedoSalesChannelsSchema } from "./sales-channels/set-redo-sales-channels-schema";
import { createSignupFormSchema } from "./signup-form/create-signup-form-schema";
import { deleteSignupFormSchema } from "./signup-form/delete-signup-form-schema";
import { getMarketingSubscriberCountsSchema } from "./signup-form/get-marketing-subscriber-counts-schema";
import { getSignupFormByIdSchema } from "./signup-form/get-signup-form-by-id-schema";
import { getSignupFormsByTeamIdSchema } from "./signup-form/get-signup-forms-by-team-id-schema";
import { unpublishSignupFormSchema } from "./signup-form/unpublish-signup-form-schema";
import { updatePublishedAtSchema } from "./signup-form/update-published-at";
import { updateSignupFormSchema } from "./signup-form/update-signup-form-schema";
import { updateNeworderItemsSchema } from "./update-new-order-items-schema";
import { updateReviewSchema } from "./update-review-schema";
import { updateSaveForLaterStorefrontDisplaySettingsSchema } from "./update-save-for-later-storefront-display-settings-schema";

const reviewForm = {
  createReviewForm: createReviewFormSchema,
  deleteReviewForm: deleteReviewFormSchema,
  getReviewForm: getReviewFormSchema,
  getReviewFormsForTeam: getReviewFormsForTeamSchema,
  getActiveReviewFormForTeam: getActiveReviewFormForTeamSchema,
  updateReviewForm: updateReviewFormSchema,
};

const redoMerchantRPC1 = {
  issueStoreCreditToCustomer: issueStoreCreditToCustomerSchema,
  getCustomerAccountsMetricsForOverview:
    getCustomerAccountsMetricsForOverviewSchema,
  getSaveForLaterStorefrontDisplaySettings:
    getSaveForLaterStorefrontDisplaySettingsSchema,
  updateSaveForLaterStorefrontDisplaySettings:
    updateSaveForLaterStorefrontDisplaySettingsSchema,
  resendEmail: resendEmailSchema,
  getReviewsByProductId: getReviewsByProductIdSchema,
  getEmailTemplateNames: getEmailTemplateNamesSchema,
  ...reviewForm,
  getReviewsByTeamId: getReviewsByTeamIdSchema,
  getReviews: getReviewsSchema,
  updateReview: updateReviewSchema,
  getReviewsAverageByProject: getReviewsAverageByProjectSchema,
  getReturnsInstantExchangeRecoveryHistory:
    getReturnsInstantExchangeRecoveryHistorySchema,
  getOrders: getOrdersSchema,
  getCustomReport: getCustomReport,
  getCustomReports: getCustomReports,
  createCustomReport: createCustomReport,
  updateCustomReport: updateCustomReport,
  deleteCustomReport: deleteCustomReport,
};

const redoMerchantRPC2 = {
  updateAssistantSettings: updateAssistantSettingsSchema,
  getAssistantSettings: getAssistantSettingsSchema,
  createSignupForm: createSignupFormSchema,
  getSignupFormById: getSignupFormByIdSchema,
  updateSignupForm: updateSignupFormSchema,
  getSignupFormsByTeamId: getSignupFormsByTeamIdSchema,
  getMarketingSubscriberCounts: getMarketingSubscriberCountsSchema,
  unpublishSignupForm: unpublishSignupFormSchema,
  updatePublishedAt: updatePublishedAtSchema,
  deleteSignupForm: deleteSignupFormSchema,
  getSalesChannelOptions: getSalesChannelOptionsSchema,
  getRedoSalesChannels: getRedoSalesChannelsSchema,
  setRedoSalesChannels: setRedoSalesChannelsSchema,
  updateNewOrderItems: updateNeworderItemsSchema,
  removeVariantExchangeItem: removeVariantExchangeItemSchema,
  getWarrantyEligibleProducts: getWarrantyEligibleProductsSchema,
  submitWarrantyRegistration: submitWarrantyRegistrationSchema,
  getRivoIntegration: getRivoIntegrationSchema,
  setRivoIntegration: setRivoIntegrationSchema,
  disconnectRivoIntegration: disconnectRivoIntegrationSchema,
  getConciergeConversations: getConciergeConversationsSchema,
  getConciergeConversationMetrics: getConciergeConversationMetricsSchema,
  calculateNewOrderValue: calculateNewOrderValueSchema,
  recreateDiscounts: recreateDiscountsSchema,
};

type MerchantRpcType = typeof redoMerchantRPC1 & typeof redoMerchantRPC2;

export const redoMerchantRPC: MerchantRpcType = {
  ...redoMerchantRPC1,
  ...redoMerchantRPC2,
};

export type RedoMerchantRpcDefinition = InferRpcDefinition<
  typeof redoMerchantRPC
>;

export const createRedoMerchantRpcClient = (
  options: ClientOptions<typeof redoMerchantRPC>,
) => createRpcClient(redoMerchantRPC, options);

export type RedoMerchantRpcClient = ReturnType<
  typeof createRedoMerchantRpcClient
>;
