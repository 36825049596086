import { ButtonHierarchy, FontWeight } from "./brand-kit";
import { FontFamily } from "./email-builder";
import { ButtonAction, InputType } from "./signup-form";

export enum BaseElementType {
  RICH_TEXT = "rich-text",
  BUTTON = "button",
  INPUT = "input",
  SHORT_ANSWER = "short-answer",
  PARAGRAPH = "paragraph",
  IMAGE = "image",
  LOGO = "logo",
  PRODUCT_DETAILS = "product-details",
  STARS = "stars",
  MEDIA_UPLOAD = "media-upload",
  SINGLE_SELECT = "single-select",
}

export const SubmittableElementType = [
  BaseElementType.SHORT_ANSWER,
  BaseElementType.PARAGRAPH,
  BaseElementType.STARS,
  BaseElementType.INPUT,
  BaseElementType.MEDIA_UPLOAD,
  BaseElementType.SINGLE_SELECT,
] as const;
export type SubmittableElementType = (typeof SubmittableElementType)[number];

export type BaseElement<T extends BaseElementType> = {
  type: T;
};

export interface RichTextElement
  extends BaseElement<BaseElementType.RICH_TEXT> {
  id: number;
  html: string;
  fontFamily: FontFamily;
  fontWeight: FontWeight;
  fontSizePx: number;
  textColor: string;
  linkColor: string;
}

export interface ButtonElement extends BaseElement<BaseElementType.BUTTON> {
  hierarchy: ButtonHierarchy;
  text: string;
  action: ButtonAction;
}

export interface InputElement extends BaseElement<BaseElementType.INPUT> {
  inputType: InputType;
  placeholderText: string;
}

export interface ShortAnswerElement
  extends BaseElement<BaseElementType.SHORT_ANSWER> {
  title: string;
  placeholderText: string;
  required: boolean;
}

export interface StarsElement extends BaseElement<BaseElementType.STARS> {
  title: string;
  required: boolean;
}

export interface LogoElement extends BaseElement<BaseElementType.LOGO> {
  widthPercentage: number;
}
export interface ImageElement extends BaseElement<BaseElementType.IMAGE> {
  url: string;
}

export interface MediaUploadElement
  extends BaseElement<BaseElementType.MEDIA_UPLOAD> {
  widthPercentage: number;
  required: boolean;
}

export interface SingleSelectElement
  extends BaseElement<BaseElementType.SINGLE_SELECT> {
  options: string[];
  required: boolean;
}

export interface ProductDetailsElement
  extends BaseElement<BaseElementType.PRODUCT_DETAILS> {
  imageWidthPercentage: number;
}

export type SubmittableElementData<T extends SubmittableElementType> = {
  type: T;
};

export interface ShortAnswerData
  extends SubmittableElementData<BaseElementType.SHORT_ANSWER> {
  answer: string;
}

export interface StarsData
  extends SubmittableElementData<BaseElementType.STARS> {
  rating: number;
}

export interface InputData
  extends SubmittableElementData<BaseElementType.INPUT> {
  value: string;
}

export interface MediaUploadData
  extends BaseElement<BaseElementType.MEDIA_UPLOAD> {
  url: string;
}

export interface SingleSelectData
  extends SubmittableElementData<BaseElementType.SINGLE_SELECT> {
  selectedOption: string;
}

export interface ParagraphElement
  extends BaseElement<BaseElementType.PARAGRAPH> {
  title: string;
  placeholderText: string;
  required: boolean;
}

export interface ParagraphData
  extends SubmittableElementData<BaseElementType.PARAGRAPH> {
  value: string;
}
