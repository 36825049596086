// extracted by mini-css-extract-plugin
export var active = "_79-b";
export var arrow = "_79-8";
export var arrowContainer = "_79-4";
export var container = "_79-0";
export var disabled = "_79-5";
export var dot = "_79-a";
export var dotContainer = "_79-9";
export var leftArrowContainer = "_79-6 _79-4";
export var rightArrowContainer = "_79-7 _79-4";
export var slide = "_79-3";
export var sliderContainer = "_79-1";
export var sliderOverflow = "_79-2";